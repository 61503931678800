<template>
  <div id="app">
    <Header/>
    <div id="view">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import "bootstrap"
import Header from '@/components/Header.vue'

export default {
  name: 'app',
  components: {
    Header
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/custom.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';

html, body, #app {
  height: 100%;
}
img {
  max-width: 100%;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#view {
  height: calc(100vh - 56px);
  overflow: auto;
}
</style>
